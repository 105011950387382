.chat-modal {
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    background: #001529;
    color: #fff;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: .5s all;
    max-height: 35px;
    max-width: 200px;
    border-radius: 10px 10px 0 0;
    box-shadow: -7px -4px 7px 0px rgb(0 0 0 / 10%);
}


.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
    z-index: 3;
}

.chat-header > span > .MuiBadge-badge:not(.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible) {
    background-color: #e13432 !important;
    transform: scale(1) translate(80%, -50%);
}

.chat-content {
    background-color: #fff;
    color: #000;
    opacity: 0;
}


.chat-modal.open {
    max-width: 350px;
    max-height: 550px;
    height: auto;
    width: 100%;
}

.chat-modal.full {
    max-width: 650px;
    max-height: 550px;
    height: auto;
    width: 100%;
}

.chat-modal.open > .chat-content {
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.chat-modal.full > .chat-content {
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.chat-support {
    display: flex;
    justify-content: space-between;
    background-color: #0361d10f;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.chat-support:hover {
    background-color: rgba(3, 97, 209, 0.07);
}

.chat-support__item {
    color: #0361d1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-support__item > span {
    color: #586f7c;
    font-weight: 500;
    font-size: 12px;
}

.chat-support__item.telegram > svg {
    color: #2da4d7;
    font-size: 25px;
}

.chat-support__item.whatsapp > svg {
    color: #33f269;
    font-size: 25px;
}

.chat-support__item.vk > svg {
    color: #0077fe;
    font-size: 25px;
}

/*dialogs*/

.dialogs {
    overflow-y: scroll;
    height: 350px;
}

.dialogs::-webkit-scrollbar-track, .messages-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
}

.dialogs::-webkit-scrollbar, .messages-content::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.dialogs::-webkit-scrollbar-thumb, .messages-content::-webkit-scrollbar-thumb {
    background-color: #0361d121;
    border-radius: 5px
}

.dialogs-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
}

.dialogs-item:hover {
    background-color: #0361d10f;
}

.dialogs-item > span > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
}

.dialogs-item > span > .MuiBadge-anchorOriginTopRightRectangle:not(.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible) {
    transform: scale(1) translate(-10%, -10%);
}

.chat-modal.full .dialogs-info {
    width: 100%;
}

.dialogs-info {
    position: relative;
    width: 80%;
    margin-left: 10px;
}

.dialogs-info > div > span {
    font-size: 13px;
    max-width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.dialogs-info > div > div {
    display: flex;
    align-items: center;
}

.dialogs-info > div > div > span {
    color: rgb(0 0 0 / 45%);
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    margin-top: 5px;
}

.dialogs-time {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
}

.no-dialogs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: #2f4550;
}

/*messages*/
.messages {
    margin: 0;
    flex-direction: column;
    height: 400px;
}

.messages.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}


.messages-header > a {
    display: flex;
    align-items: center;
    width: 100%;
    color: #000;
}

.messages-header {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f4f4f9;
    border-radius: 10px;
    padding: 10px;
    color: #000;
}

.messages-header > svg {
    background-color: #dedede;
    border-radius: 50px;
}

.messages-header > svg:hover {
    background-color: #d0d0d0;
}

.messages-content {
    overflow-y: scroll;
    height: 100%;
    margin: 10px 0 50px 0;
}

.chat-group:last-child {
    margin-bottom: 120px;
}

.messages-header > img, .messages-header > a > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
    margin: 0 10px;
}

.messages-header__info {
    display: flex;
    flex-direction: column;
}

.messages-header__info > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.messages-form {
    position: absolute;
    bottom: 0;
    width: 95%;
    /* justify-content: space-between; */
    /* display: flex; */
    background-color: #f4f4f9;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.messages-form > form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.messages-form > form > textarea {
    width: 100%;
    border: 1px solid #e3e3e3;
    background-color: #ffff;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    padding-top: 5px;
}

.messages-form > form > svg {
    color: #0361d1;
}

.chat-message {
    background: #0361d11f;
    color: #000;
    width: fit-content;
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}

.chat-group-date {
    background: #f0f2f5;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 15px;
    border-radius: 20px;
    color: #000000d9;
    margin-bottom: 10px;
}

.chat-group.no-messages {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.chat-send-message {
    width: 100%;
    min-height: 50px;
    max-height: 132px;
    /*position: absolute;*/
    /*bottom: 0;*/
}

.chat-send-message {
    padding: 10px 20px;
    background: #001529;
    display: flex;
    flex-direction: column;
}

.chat-send-message > img {
    width: 50px;
    margin: 10px;
}

.chat-send-message > form {
    width: 100%;
    display: flex;
    align-items: center;
}

.chat-send-message > form > textarea {
    width: 100%;
    border: none;
    border-radius: 6px;
    padding: 10px;
    background-color: #fff;
}

.chat-send-message > span {
    font-size: 25px;
    color: rgba(234, 234, 234, 0.78);
    margin-left: 20px;
    cursor: pointer;
}

.chat-send-message > span:hover {
    color: #fff;
}

.chat-messages_item {
    display: flex;
    position: relative;
    width: 100%;
}

.chat-messages_item.customer {
    justify-content: flex-end;
}

.chat-message.shop > .chat-time {
    right: -45px;
}

.chat-username {
    font-weight: 500;
}

.chat-message.customer > .chat-time {
    left: -45px;
}

.chat-message.support > .chat-time {
    right: -45px;
}

.chat-message.shop {
    background: #f0f2f5;
    color: #000;
}

.chat-message.support {
    background: #1890ff;
    color: #fff;
}

.chat-time {
    position: absolute;
    bottom: 0px;
    font-size: 13px;
    color: #b9b9b9;
}

.chat-text {
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/
    word-wrap: break-word;
}

.no-chats {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#icon-button-file {
    display: none;
}

.messages-form>form>input[type='text'] {
    width: 100%;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    /*padding-top: 5px;*/
}

@media screen and (max-width: 768px) {
    .chat-modal.full .dialogs-info {
        width: 80%;
    }
    .chat-modal {
        z-index: 4;
    }
}