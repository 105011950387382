@import url('./laximo.css');

ul, li {
    margin: 0;
    padding: 0;
}

.table-row-transacted {
    background-color: #1890ff2e;
}

.logo {
    width: 200px;
    height: 32px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
}

.logo:after {
    content: "SP-PARTOBO";
}

.app-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-tel-input > input.form-control {
    width: 100%;
}

.reset-margin-form .ant-form-item {
    margin-bottom: 5px;
}

.reset-margin-form .ant-form-item-label {
    padding: 0;
}

@media screen and (max-width: 400px) {
    .logo:after {
        content: "SP-P";
    }
}
