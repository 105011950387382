.founded-cars-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
    background-color: #ececec;
}

.founded-cars-list__item {
    width: 49%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 8px;
    transition: .5s all;
}

/* .founded-cars-list__item:nth-child(2n) {
    margin-left: 10px;
} */

.founded-cars-list__item.active {
    border: 2px solid #1990ff;
}

.founded-cars-list__item:hover {
    cursor: pointer;
    border: 2px solid #1990ff;
}

.tree-title__category,
.tree-title {
    margin-bottom: 15px;
    cursor: pointer;
    /* width: fit-content; */
    font-weight: 400;
}

.tree-title__category {
    background-color: #1990ff;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    border-radius: 8px;
}

.tree-title:hover, .tree-title.active {
    color: #1990ff;
}
.tree-title > span.anticon {
    margin-right: 10px;
    color: #1990ff;
}
.tree-childrens {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #1990ff;
}

.units-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    background-color: #ececec;
}

.units-item {
    width: 33%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 8px;
    transition: .5s all;
    text-align: center;
    font-weight: 500;
}

.units-item > img {
    width: 100%;
    object-fit: contain;
    height: 200px;
}

.units-item > p {
    margin: 0;
}

.units-item.active {
    border: 2px solid #1990ff;
}

.units-item:hover {
    cursor: pointer;
    border: 2px solid #1990ff;
}

.details-list {
    max-height: 500px;
    overflow-y: auto;
    text-align: center;
}

.details-list__item {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.details-list__item.active {
    color: #1990ff;
    font-weight: 800;
}

.details-list__item:hover {
    color: #1990ff;
    cursor: pointer;
}


/* cars catalog */
.cars-catalog {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.cars-catalog__item {
    margin-bottom: 10px;
    font-weight: 500;
}

.cars-catalog__item:hover {
    cursor: pointer;
    color: #1990ff;
}